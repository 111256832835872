import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../reducers/user.reducer';

const selectUserState = createFeatureSelector<UserState>('user');
export const selectIsLoggedIn = createSelector(
    selectUserState,
    (state: UserState) => state.isLoggedIn
);
export const selectAccount = createSelector(selectUserState, (state: UserState) => state.account);
export const selectUserRoles = createSelector(selectUserState, (state: UserState) => {
    return {
        isAdmin: state.isAdmin,
        isManager: state.isManager,
        isWriter: state.isWriter,
        isPremium: state.isPremium,
        isTrial: state.isTrial,
        isFree: state.isFree,
        isPlayer: state.isPlayer,
        isInfluencer: state.isInfluencer,
        allRoles: state.roles
    };
});