export enum CustomerSource {
    STRIPE = 'stripe',
    BRAINTREE = 'braintree'
}

export interface Customer {
    _id: string;
    updatedAt: Date;
    createdAt: Date;
    source: CustomerSource;
    userId: string;
    balance: number;
    isDeleted: boolean;
}