import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Customer, CustomerSource } from '../../models/customer.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { SubscriptionStatus, UserSubscription } from '../../models/user-subscription.model';
import {
    customersAdapter,
    paymentMethodsAdapter,
    paymentsAdapter,
    PaymentState,
    subscriptionsAdapter
} from '../reducers/payment.reducer';
import { GlobalState } from '../store';

const selectPaymentState = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
    'payment'
).projector((state: GlobalState) => state.payment);

const customersSelectors = customersAdapter.getSelectors();
const subscriptionsSelectors = subscriptionsAdapter.getSelectors();
const paymentMethodsSelectors = paymentMethodsAdapter.getSelectors();
const paymentsSelectors = paymentsAdapter.getSelectors();

/**
 * Customers
 */
export const selectCustomersSubstate = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.customers
);
export const selectCustomers = createSelector(
    selectCustomersSubstate,
    customersSelectors.selectAll
);
export const selectStripeCustomer = createSelector(
    selectCustomers,
    (customers: Customer[]) => customers.find(c => c.source === CustomerSource.STRIPE) || null
);

/**
 * Subscriptions
 */
export const selectSubscriptionsSubstate = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.subscriptions
);
export const selectSubscriptions = createSelector(
    selectSubscriptionsSubstate,
    subscriptionsSelectors.selectAll
);
export const selectCurrentSubscription = createSelector(
    selectSubscriptions,
    (subscriptions: UserSubscription[]) => {
        return (
            [...subscriptions].sort(
                (a, b) => new Date(b.periodEnd).getTime() - new Date(a.periodEnd).getTime()
            )[0] || null
        );
    }
);
export const selectCurrentPendingSubscription = createSelector(
    selectSubscriptions,
    (subscriptions: UserSubscription[]) => {
        return (
            [...subscriptions]
                .sort((a, b) => new Date(b.periodEnd).getTime() - new Date(a.periodEnd).getTime())
                .find(
                    sub =>
                        sub.status === SubscriptionStatus.PENDING ||
                        sub.status === SubscriptionStatus.TRIALING
                ) || null
        );
    }
);
export const selectIncompleteSubscription = createSelector(
    selectSubscriptions,
    (subscriptions: UserSubscription[]) =>
        (subscriptions && subscriptions.find(s => s.status === SubscriptionStatus.INCOMPLETE)) ||
        null
);
/**
 * Payment Methods
 */
export const selectPaymentMethodsSubstate = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.paymentMethods
);
export const selectPaymentMethods = createSelector(
    selectPaymentMethodsSubstate,
    paymentMethodsSelectors.selectAll
);
export const selectDefaultPaymentMethod = createSelector(
    selectPaymentMethods,
    (paymentMethods: PaymentMethod[]) => paymentMethods.find(pm => pm.isDefault) || null
);

/**
 * Payments
 */
export const selectPaymentsSubstate = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.payments
);
export const selectPayments = createSelector(selectPaymentsSubstate, paymentsSelectors.selectAll);

export const selectProration = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.proration
);
export const selectStripePublishableKey = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.stripePublishableKey
);
export const selectCurrencySettings = createSelector(
    selectPaymentState,
    (state: PaymentState) => state.currencySettings
);
