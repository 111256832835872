import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { Customer } from '../../models/customer.model';
import { UserSubscription, UserSubscriptionProration } from '../../models/user-subscription.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { UserPayment } from '../../models/payment.model';
import { CurrencySettings } from '../../models/payment-settings.model';

import * as paymentActions from '../actions/payment.actions';
import * as authActions from '../actions/auth.actions';

const selectId = (entity: any) => entity.id || entity._id;

export const customersAdapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
    selectId
});
export const subscriptionsAdapter: EntityAdapter<UserSubscription> = createEntityAdapter<
    UserSubscription
>({
    selectId
});
export const paymentMethodsAdapter: EntityAdapter<PaymentMethod> = createEntityAdapter<
    PaymentMethod
>({
    selectId
});
export const paymentsAdapter: EntityAdapter<UserPayment> = createEntityAdapter<UserPayment>({
    selectId
});

export interface PaymentState {
    customers: EntityState<Customer>;
    subscriptions: EntityState<UserSubscription>;
    paymentMethods: EntityState<PaymentMethod>;
    payments: EntityState<UserPayment>;
    proration: UserSubscriptionProration | null;
    stripePublishableKey: string | null;
    currencySettings: CurrencySettings | null;
}

export const paymentInitialState: PaymentState = {
    customers: customersAdapter.getInitialState(),
    subscriptions: subscriptionsAdapter.getInitialState(),
    paymentMethods: paymentMethodsAdapter.getInitialState(),
    payments: paymentsAdapter.getInitialState(),
    proration: null,
    stripePublishableKey: null,
    currencySettings: null
};

export const paymentReducer = createReducer(
    paymentInitialState,
    on(paymentActions.loadCustomersSuccess, (state, { payload }) => ({
        ...state,
        customers: customersAdapter.setAll(payload, state.customers)
    })),
    on(paymentActions.loadSubscriptionsSuccess, (state, { payload }) => ({
        ...state,
        subscriptions: subscriptionsAdapter.setAll(payload, state.subscriptions)
    })),
    on(paymentActions.newSubscriptionSuccess, (state, { payload }) => ({
        ...state,
        subscriptions: subscriptionsAdapter.addOne(payload, state.subscriptions)
    })),
    on(paymentActions.newStripeSubscriptionSuccess, (state, { payload }) => ({
        ...state,
        subscriptions: subscriptionsAdapter.addOne(payload.sub, state.subscriptions)
    })),
    on(
        paymentActions.cancelSubscriptionSuccess,
        paymentActions.changeSubscriptionPlanSuccess,
        (state, { payload }) => ({
            ...state,
            subscriptions: subscriptionsAdapter.updateOne(
                {
                    id: payload._id,
                    changes: payload
                },
                state.subscriptions
            )
        })
    ),
    on(paymentActions.loadPaymentMethodsSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.setAll(payload, state.paymentMethods)
    })),
    on(paymentActions.addPaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.addOne(payload, state.paymentMethods)
    })),
    on(paymentActions.addPaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.map(
            pm => (pm._id !== payload._id && payload.isDefault ? { ...pm, isDefault: false } : pm),
            state.paymentMethods
        )
    })),
    on(paymentActions.updatePaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.map(
            pm => (pm._id !== payload._id ? { ...pm, isDefault: false } : pm),
            state.paymentMethods
        )
    })),
    on(paymentActions.updatePaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.updateOne(
            { id: payload._id, changes: payload },
            state.paymentMethods
        )
    })),
    on(paymentActions.removePaymentMethodSuccess, (state, { payload }) => ({
        ...state,
        paymentMethods: paymentMethodsAdapter.removeOne(payload._id, state.paymentMethods)
    })),
    on(paymentActions.loadPaymentsSuccess, (state, { payload }) => ({
        ...state,
        payments: paymentsAdapter.setAll(payload, state.payments)
    })),
    on(paymentActions.loadSubscriptionProrationSuccess, (state, { payload }) => ({
        ...state,
        proration: payload
    })),
    on(paymentActions.loadStripePublishableKeySuccess, (state, { payload }) => ({
        ...state,
        stripePublishableKey: payload.publishableKey
    })),
    on(paymentActions.loadCurrencySettingsSuccess, (state, { payload }) => ({
        ...state,
        currencySettings: payload
    })),
    on(authActions.logoutUserSuccess, state => ({
        ...paymentInitialState
    }))
);
