import { createAction, props } from '@ngrx/store';
import { Customer } from '../../models/customer.model';
import { APIError } from '../../models/error.model';
import { PaymentMethod, PaymentMethodType } from '../../models/payment-method.model';
import { UserPayment } from '../../models/payment.model';
import { CurrencySettings } from '../../models/payment-settings.model';
import { UserSubscription, UserSubscriptionProration } from '../../models/user-subscription.model';

export enum SubscriptionsActionsTypes {
    NewBraintreeSubscription = '[Checkout Page] New Braintree Subscription',

    NewStripeSubscription = '[Checkout Page] New Stripe Subscription',
    NewStripeSubscriptionSuccess = '[Payment Effects] New Stripe Subscription Success',

    NewSubscriptionSuccess = '[Payment Effects] New Subscription Success',
    NewSubscriptionFailure = '[Payment Effects] New Subscription Failure',

    RetryStripeSubscriptionPayment = '[Checkout Page] Retry Stripe Subscription Payment',
    RetryStripeSubscriptionPaymentSuccess = '[Payment Effects] Retry Stripe Subscription Payment Success',
    RetryStripeSubscriptionPaymentFailure = '[Payment Effects] Retry Stripe Subscription Payment Failure',

    LoadSubscriptions = '[Checkout Page] Load Subscriptions',
    LoadSubscriptionsSuccess = '[Payment Effects] Load Subscriptions Success',
    LoadSubscriptionsFailure = '[Payment Effects] Load Subscriptions Failure',

    LoadSubscriptionProration = '[Account Page] Load Subscription Proration',
    LoadSubscriptionProrationSuccess = '[Payment Effects] Load Subscription Proration Success',
    LoadSubscriptionProrationFailure = '[Payment Effects] Load Subscription Proration Failure',

    CancelSubscription = '[Account Page] Cancel Subscription',
    CancelSubscriptionSuccess = '[Payment Effects] Cancel Subscription Success',
    CancelSubscriptionFailure = '[Payment Effects] Cancel Subscription Failure',

    ChangeSubscriptionPlan = '[Account Page] Change Subscription Plan',
    ChangeSubscriptionPlanSuccess = '[Payment Effects] Change Subscription Plan Success',
    ChangeSubscriptionPlanFailure = '[Payment Effects] Change Subscription Plan Failure'
}

export enum SettingsActionsTypes {
    LoadStripePublishableKey = '[Checkout Page] Load Stripe Publishable Key',
    LoadStripePublishableKeySuccess = '[Checkout Page] Load Stripe Publishable Key Success',
    LoadStripePublishableKeyFailure = '[Checkout Page] Load Stripe Publishable Key Failure',

    LoadPaymentSettings = '[App Global] Load Payment Settings',
    LoadPaymentSettingsSuccess = '[App Global] Load Payment Settings Success',
    LoadPaymentSettingsFailure = '[App Global] Load Payment Settings Failure'
}

export enum PaymentMethodsActionsTypes {
    LoadPaymentMethods = '[Account Page] Load Payment Methods',
    LoadPaymentMethodsSuccess = '[Account Page] Load Payment Methods Success',
    LoadPaymentMethodsFailure = '[Account Page] Load Payment Methods Failure',

    AddPaymentMethod = '[Account Page] Add Payment Method',
    AddPaymentMethodSuccess = '[Account Page] Add Payment Method Success',
    AddPaymentMethodFailure = '[Account Page] Add Payment Method Failure',

    UpdatePaymentMethod = '[Account Page] Update Payment Method',
    UpdatePaymentMethodSuccess = '[Account Page] Update Payment Method Success',
    UpdatePaymentMethodFailure = '[Account Page] Update Payment Method Failure',

    RemovePaymentMethod = '[Account Page] Remove Payment Method',
    RemovePaymentMethodSuccess = '[Account Page] Remove Payment Method Success',
    RemovePaymentMethodFailure = '[Account Page] Remove Payment Method Failure'
}

export enum CustomersActionsTypes {
    LoadCustomers = '[Account Page] Load Customers',
    LoadCustomersSuccess = '[Account Page] Load Customers Success',
    LoadCustomersFailure = '[Account Page] Load Customers Failure'
}

export enum PaymentsActionsTypes {
    LoadPayments = '[Account Page] Load Payments',
    LoadPaymentsSuccess = '[Account Page] Load Payments Success',
    LoadPaymentsFailure = '[Account Page] Load Payments Failure'
}

/**
 * Subscriptions actions.
 */

export const newBraintreeSubscription = createAction(
    SubscriptionsActionsTypes.NewBraintreeSubscription,
    props<{
        payload: {
            plan: string;
            paymentMethodToken?: string;
            paypalEmail?: string;
            promoCode?: string;
            campaignId?: string;
            deviceData?: string;
            token?: string;
            currency?: string;
        };
    }>()
);
export const newStripeSubscription = createAction(
    SubscriptionsActionsTypes.NewStripeSubscription,
    props<{
        payload: {
            plan: string;
            paymentMethodToken?: string;
            paymentMethodId?: string;
            promoCode?: string;
            campaignId?: string;
            deviceData?: string;
            currency?: string;
        };
    }>()
);
export const newStripeSubscriptionSuccess = createAction(
    SubscriptionsActionsTypes.NewStripeSubscriptionSuccess,
    props<{
        payload: { sub: UserSubscription; sourceSub: any; };
    }>()
);
export const newSubscriptionSuccess = createAction(
    SubscriptionsActionsTypes.NewSubscriptionSuccess,
    props<{ payload: UserSubscription }>()
);
export const newSubscriptionFailure = createAction(
    SubscriptionsActionsTypes.NewSubscriptionFailure,
    props<{ payload: APIError }>()
);
export const retryStripeSubscriptionPayment = createAction(
    SubscriptionsActionsTypes.RetryStripeSubscriptionPayment,
    props<{
        payload: {
            subscriptionId: string;
            data?: {
                paymentMethodId?: string;
            };
        };
    }>()
);
export const retryStripeSubscriptionPaymentSuccess = createAction(
    SubscriptionsActionsTypes.RetryStripeSubscriptionPaymentSuccess,
    props<{ payload: { sub: UserSubscription; sourceSub: any; paymentMethodId: string } }>()
);
export const retryStripeSubscriptionPaymentFailure = createAction(
    SubscriptionsActionsTypes.RetryStripeSubscriptionPaymentFailure,
    props<{ payload: APIError }>()
);
export const loadSubscriptions = createAction(SubscriptionsActionsTypes.LoadSubscriptions);
export const loadSubscriptionsSuccess = createAction(
    SubscriptionsActionsTypes.LoadSubscriptionsSuccess,
    props<{ payload: UserSubscription[] }>()
);
export const loadSubscriptionsFailure = createAction(
    SubscriptionsActionsTypes.LoadSubscriptionsFailure,
    props<{ payload: APIError }>()
);

export const loadSubscriptionProration = createAction(
    SubscriptionsActionsTypes.LoadSubscriptionProration,
    props<{
        payload: {
            plan: string;
        };
    }>()
);
export const loadSubscriptionProrationSuccess = createAction(
    SubscriptionsActionsTypes.LoadSubscriptionProrationSuccess,
    props<{ payload: UserSubscriptionProration }>()
);
export const loadSubscriptionProrationFailure = createAction(
    SubscriptionsActionsTypes.LoadSubscriptionProrationFailure,
    props<{ payload: APIError }>()
);

export const cancelSubscription = createAction(
    SubscriptionsActionsTypes.CancelSubscription,
    props<{
        payload: {
            subscriptionId: string;
            data: {
                feedback?: string;
            };
        };
    }>()
);
export const cancelSubscriptionSuccess = createAction(
    SubscriptionsActionsTypes.CancelSubscriptionSuccess,
    props<{ payload: UserSubscription }>()
);
export const cancelSubscriptionFailure = createAction(
    SubscriptionsActionsTypes.CancelSubscriptionFailure,
    props<{ payload: APIError }>()
);

export const changeSubscriptionPlan = createAction(
    SubscriptionsActionsTypes.ChangeSubscriptionPlan,
    props<{
        payload: {
            subscriptionId: string;
            data: {
                plan: string;
                prorationDate: Date;
            };
        };
    }>()
);
export const changeSubscriptionPlanSuccess = createAction(
    SubscriptionsActionsTypes.ChangeSubscriptionPlanSuccess,
    props<{ payload: UserSubscription }>()
);
export const changeSubscriptionPlanFailure = createAction(
    SubscriptionsActionsTypes.ChangeSubscriptionPlanFailure,
    props<{ payload: APIError }>()
);

/**
 * Settings actions.
 */
export const loadStripePublishableKey = createAction(SettingsActionsTypes.LoadStripePublishableKey);
export const loadStripePublishableKeySuccess = createAction(
    SettingsActionsTypes.LoadStripePublishableKeySuccess,
    props<{
        payload: {
            publishableKey: string;
        };
    }>()
);
export const loadStripePublishableKeyFailure = createAction(
    SettingsActionsTypes.LoadStripePublishableKeyFailure,
    props<{ payload: APIError }>()
);

export const loadCurrencySettings = createAction(SettingsActionsTypes.LoadPaymentSettings);
export const loadCurrencySettingsSuccess = createAction(
    SettingsActionsTypes.LoadPaymentSettingsSuccess,
    props<{
        payload: CurrencySettings;
    }>()
);
export const loadCurrencySettingsFailure = createAction(
    SettingsActionsTypes.LoadPaymentSettingsFailure,
    props<{ payload: APIError }>()
);

/**
 * Payment methods actions.
 */
export const loadPaymentMethods = createAction(PaymentMethodsActionsTypes.LoadPaymentMethods);
export const loadPaymentMethodsSuccess = createAction(
    PaymentMethodsActionsTypes.LoadPaymentMethodsSuccess,
    props<{
        payload: PaymentMethod[];
    }>()
);
export const loadPaymentMethodsFailure = createAction(
    PaymentMethodsActionsTypes.LoadPaymentMethodsFailure,
    props<{ payload: APIError }>()
);

export const addPaymentMethod = createAction(
    PaymentMethodsActionsTypes.AddPaymentMethod,
    props<{
        payload: {
            isDefault: boolean;
            type: PaymentMethodType;
            token: string;
            paypalEmail?: string;
            currency: string;
        };
    }>()
);
export const addPaymentMethodSuccess = createAction(
    PaymentMethodsActionsTypes.AddPaymentMethodSuccess,
    props<{
        payload: PaymentMethod;
    }>()
);
export const addPaymentMethodFailure = createAction(
    PaymentMethodsActionsTypes.AddPaymentMethodFailure,
    props<{ payload: APIError }>()
);

export const updatePaymentMethod = createAction(
    PaymentMethodsActionsTypes.UpdatePaymentMethod,
    props<{
        payload: {
            paymentMethodId: string;
            data: {
                isDefault: boolean;
            };
        };
    }>()
);
export const updatePaymentMethodSuccess = createAction(
    PaymentMethodsActionsTypes.UpdatePaymentMethodSuccess,
    props<{
        payload: PaymentMethod;
    }>()
);
export const updatePaymentMethodFailure = createAction(
    PaymentMethodsActionsTypes.UpdatePaymentMethodFailure,
    props<{ payload: APIError }>()
);

export const removePaymentMethod = createAction(
    PaymentMethodsActionsTypes.RemovePaymentMethod,
    props<{
        payload: {
            paymentMethodId: string;
        };
    }>()
);
export const removePaymentMethodSuccess = createAction(
    PaymentMethodsActionsTypes.RemovePaymentMethodSuccess,
    props<{
        payload: PaymentMethod;
    }>()
);
export const removePaymentMethodFailure = createAction(
    PaymentMethodsActionsTypes.RemovePaymentMethodFailure,
    props<{ payload: APIError }>()
);

/**
 * Customers actions.
 */
export const loadCustomers = createAction(CustomersActionsTypes.LoadCustomers);
export const loadCustomersSuccess = createAction(
    CustomersActionsTypes.LoadCustomersSuccess,
    props<{
        payload: Customer[];
    }>()
);
export const loadCustomersFailure = createAction(
    CustomersActionsTypes.LoadCustomersFailure,
    props<{ payload: APIError }>()
);

/**
 * Payments actions.
 */
export const loadPayments = createAction(PaymentsActionsTypes.LoadPayments);
export const loadPaymentsSuccess = createAction(
    PaymentsActionsTypes.LoadPaymentsSuccess,
    props<{
        payload: UserPayment[];
    }>()
);
export const loadPaymentsFailure = createAction(
    PaymentsActionsTypes.LoadPaymentsFailure,
    props<{ payload: APIError }>()
);
