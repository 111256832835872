import { Discount } from './discount.model';

export enum SubscriptionStatus {
    INCOMPLETE = 'incomplete',
    ACTIVE = 'active',
    FREE = 'free',
    CANCELED = 'canceled',
    LIFETIME = 'lifetime',
    PAST_DUE = 'past_due',
    PENDING = 'pending',
    EXPIRED = 'expired',
    TRIALING = 'trialing',
    SUSPENDED = 'suspended',
    UNPAID = 'unpaid'
}

export const SubscriptionStatuses = Object.values(SubscriptionStatus) as SubscriptionStatus[];

export enum SubscriptionSource {
    STRIPE = 'stripe',
    BRAINTREE = 'braintree',
    APPSTORE = 'appstore'
}

export interface UserSubscription {
    _id: string;
    updatedAt: Date;
    createdAt: Date;
    userId: string;
    status: SubscriptionStatus;
    periodStart: Date;
    periodEnd: Date;
    customer: string;
    sourceId: string;
    source: SubscriptionSource;
    plan: string;
    planName: string;
    savingsPerYear: string;
    price: number;
    hasExpired: boolean;
    planMonths: number;
    pricePerMonth: number;
    discountedPricePerMonth: number;
    nextBillAmount: number;
    discounts?: Discount[];
    currency: string;
}

export interface UserSubscriptionProration {
    total: number;
    creditAmount: number;
    description: string | null;
    prorationDate: Date;
}
